import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal'
import moment from 'moment'
import { getSchedulesById } from '../../api/schedules';

export default function ModalDetailRelocate({id, show, setShow}) {
  let [data, setData] = useState({});
  let [error, setError] = useState('');

  useEffect(() => {
    const getData = async () => {
      try {
        let { data } = await getSchedulesById(id);
        setData(data.data);
      } catch (error) {
        console.error(error)
        if (error.response) {
          setError(error.response.data.message)
        } else {
          setError('Terjadi kesalahan')
        }
      }
    }

    if (id && show) {
      setError('')
      getData()
    }
  }, [id, show])

  const handleClose = () => {
    setData({});
    setError('');
    setShow('')
  }

  return (
    <Modal show={show} onHide={() => handleClose()} size='xl'>
      <Modal.Header closeButton>
        <Modal.Title>Detail Relocate</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {error && 
          <div className={`alert alert-danger alert-dismissible fade show`} role="alert">
            {error}
            <button type="button" className="close"  onClick={() => setError('')} aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        }

        <div className="row">
          <div className="col-lg-6">
            <h3>Data Pelanggan</h3>
            <table className='table table-bordered'>
              <tbody>
                <tr>
                  <td>No. Reg</td>
                  <td>{data.registration?.registration_number || "-"}</td>
                </tr>
                <tr>
                  <td>Nama Pelanggan</td>
                  <td>{data.registration?.name || "-"}</td>
                </tr>
                <tr>
                  <td>Alamat Instalasi Baru</td>
                  <td>
                    {data.address 
                      ? data.address + " RT " + data.rt + "/RW " + data.rw   + ", " + data.addresses.kelurahan + ", " + data.addresses.kecamatan + ", " + data.addresses.kota_kab + ", " + data.addresses.province + ", " + data.addresses.postal_code
                      : "-"
                    }
                  </td>
                </tr>
                <tr>
                  <td>No. Telp</td>
                  <td>{data.registration?.phone_number || "-"}</td>
                </tr>
                <tr>
                  <td>Alasan Relocate</td>
                  <td>{data.description || "-"}</td>
                </tr>
                <tr>
                  <td>Status Relocate</td>
                  <td>{data.status || "-"}</td>
                </tr>
                <tr>
                  <td>Catatan Teknisi</td>
                  <td>{data.additional_note || "-"}</td>
                </tr>
                <tr>
                  <td>Nama Teknisi</td>
                  <td>
                    <ol className="pl-3">
                      {data.technicians?.map((technician, indexY) => (
                        <li key={indexY}>{technician.name}</li>
                      ))}
                    </ol>
                  </td>
                </tr>
                <tr>
                  <td>Alamat Instalasi Lama</td>
                  <td>
                    {data.relocate_history 
                      ? data.relocate_history.address + " RT " + data.relocate_history.rt + "/RW " + data.relocate_history.rw   + ", " + data.relocate_history.addresses.kelurahan + ", " + data.relocate_history.addresses.kecamatan + ", " + data.relocate_history.addresses.kota_kab + ", " + data.relocate_history.addresses.province + ", " + data.relocate_history.addresses.postal_code
                      : "-"
                    }
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-lg-6">
            {data.registration?.inventory_modem_pair && 
              <>
                <h3>Data Modem</h3>
                <table className='table table-bordered'>
                  <tbody>
                    <tr>
                      <td>SN Modem</td>
                      <td>{data.registration?.inventory_modem_pair.modem.serial_number_modem || "-"}</td>
                    </tr>
                    <tr>
                      <td>GPON</td>
                      <td>{data.registration?.inventory_modem_pair.modem.serial_number_gpon || "-"}</td>
                    </tr>
                    <tr>
                      <td>Framed Pool</td>
                      <td>{data.registration?.inventory_modem_pair.modem.framed_pool || "-"}</td>
                    </tr>
                    <tr>
                      <td>VLAN</td>
                      <td>{data.registration?.inventory_modem_pair.modem.vlan || "-"}</td>
                    </tr>
                    <tr>
                      <td>ODP</td>
                      <td>{data.registration?.inventory_modem_pair.modem.odp || "-"}</td>
                    </tr>
                    <tr>
                      <td>ODC</td>
                      <td>{data.registration?.inventory_modem_pair.modem.odc || "-"}</td>
                    </tr>
                    <tr>
                      <td>OLT</td>
                      <td>{data.registration?.inventory_modem_pair.modem.olt || "-"}</td>
                    </tr>
                  </tbody>
                </table>
              </>
            }

            {data.bast &&
              <TableBast bast={data.bast} />
            }
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <button className="btn btn-secondary" onClick={() => handleClose()}>Close</button>
      </Modal.Footer>
    </Modal>
  )
}

function TableBast({ bast }) {
  let modemImg = "";
  let signatureImg = "";
  let pillarImg = "";
  let speedTestImg = "";
  
  bast.attachments.forEach(attachment => {
    if (attachment.type === "modem") {
      modemImg = attachment.attachment_url;
    }

    if (attachment.type === "signature") {
      signatureImg = attachment.attachment_url;
    }
    
    if (attachment.type === "pillar") {
      pillarImg = attachment.attachment_url;
    }

    if (attachment.type === "speed-test") {
      speedTestImg = attachment.attachment_url;
    }
  });
  
  return (
    <table className='table table-bordered'>
      <tbody>
        <tr>
          <td>Tgl. BAST Relokasi</td>
          <td>{bast.createdAt ?  moment.unix(bast.createdAt).format("DD MMM YYYY - HH:mm") : "-"}</td>
        </tr>
        <tr>
          <td>Catatan Material</td>
          <td>{bast.material_note || "-"}</td>
        </tr>
        <tr>
          <td>SN Modem</td>
          <td>{bast.ont_number || "-"}</td>
        </tr>
        <tr>
          <td>ODP</td>
          <td>{bast.odp_number || "-"}</td>
        </tr>
        <tr>
          <td>ODC</td>
          <td>{bast.odc_number || "-"}</td>
        </tr>
        <tr>
          <td>OLT</td>
          <td>{bast.olt_number || "-"}</td>
        </tr>
        <tr>
          <td>Foto Lokasi Modem</td>
          <td>
            <img src={modemImg} alt="Foto Lokasi Modem" width={200} />
          </td>
        </tr>
        <tr>
          <td>Foto Tiang Jaringan</td>
          <td>
            <img src={pillarImg} alt="Foto Tiang Jaringan" width={200} />
          </td>
        </tr>
        <tr>
          <td>Uji Kecepatan</td>
          <td>
            <img src={speedTestImg} alt="Uji Kecepatan" width={200} />
          </td>
        </tr>
        <tr>
          <td>Tanda Tangan</td>
          <td>
            <img src={signatureImg} alt="Tanda Tangan" width={200} />
          </td>
        </tr>
      </tbody>
    </table>
  )
}