import { useState } from "react";
import { Modal } from "react-bootstrap";
import { checkCustomerModem, restartCustomerModem } from "../../api/customer";
import { useGetCustomerHooks } from "../../utils/CustomerUtilHooks";
import AlertBox from "../AlertBox";

import { useControlPermission } from "../../utils/ActionPermissionUtils";
import { LoadingInsideBox, LoadingInsideBoxWrapper } from "../Loading";
export default function ModalInfoCustomerModem({ show, id, onClose }) {
  let { hasAccess } = useControlPermission();

  let [loading, setLoading] = useState(false);
  let [response, setResponse] = useState({});
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });
  const { data, loading: dataLoading } = useGetCustomerHooks({ id });

  const resetData = () => {
    setResponse({});
    setAlert({ show: false, message: "", type: "" });
  };

  const handleClose = () => {
    onClose();
    resetData();
  };

  const onSuccess = (message) => {
    setAlert({ show: true, message: message, type: "success" });
  };

  const onError = (message) => {
    setAlert({ show: true, message: message, type: "danger" });
  };

  const onLoading = (value) => {
    if (value) {
      setAlert({ show: false, message: "", type: "" });
    }
    setLoading(value);
  };

  return (
    <Modal show={show} onHide={() => handleClose()} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>
          Info Modem - {data.registration?.registration_number} - {data.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <LoadingInsideBoxWrapper>
          {dataLoading && <LoadingInsideBox />}
          <div className="row">
            <div className="col-12 col-lg-4">
              <table className="table">
                <tbody>
                  {data.inventory_modem_pair && (
                    <>
                      <h5>Data Perangkat</h5>
                      <table className="table table-bordered">
                        <tbody>
                          <tr>
                            <td>Serial Modem</td>
                            <td>{data.inventory_modem_pair?.modem.serial_number_modem}</td>
                          </tr>
                          <tr>
                            <td>Brand</td>
                            <td>{data.inventory_modem_pair?.modem.brand}</td>
                          </tr>
                          <tr>
                            <td>Tipe</td>
                            <td>{data.inventory_modem_pair?.modem.type}</td>
                          </tr>
                          <tr>
                            <td>Username</td>
                            <td>{data.inventory_modem_pair?.modem.pppoe_username}</td>
                          </tr>
                          <tr>
                            <td>Password</td>
                            <td>{data.inventory_modem_pair?.modem.pppoe_password}</td>
                          </tr>
                          <tr>
                            <td>OLT</td>
                            <td>{data.inventory_modem_pair?.modem.olt}</td>
                          </tr>
                          <tr>
                            <td>VLAN</td>
                            <td>{data.inventory_modem_pair?.modem.vlan}</td>
                          </tr>
                          <tr>
                            <td>Framed Pool</td>
                            <td>{data.inventory_modem_pair?.modem.framed_pool}</td>
                          </tr>
                          <tr>
                            <td>Nomor ODC</td>
                            <td>{data.inventory_modem_pair?.modem.odc}</td>
                          </tr>
                          <tr>
                            <td>Nomor ODP</td>
                            <td>{data.inventory_modem_pair?.modem.odp}</td>
                          </tr>
                        </tbody>
                      </table>
                    </>
                  )}
                </tbody>
              </table>
            </div>
            <div className="col-12 col-lg-8">
              <h5>Status Perangkat</h5>
              <div className="mb-2">
                {hasAccess("view-modem-info-customer") && (
                  <ButtonCheckStatusModem
                    id={id}
                    onSuccess={onSuccess}
                    onError={onError}
                    setResponse={setResponse}
                    onLoading={onLoading}
                    disabled={loading || data.inventory_modem_pair?.modem.automation !== 1}
                  />
                )}
                {hasAccess("restart-modem-customer") && (
                  <ButtonRestartModem
                    id={id}
                    onSuccess={onSuccess}
                    onError={onError}
                    setResponse={setResponse}
                    onLoading={onLoading}
                    disabled={loading || data.inventory_modem_pair?.modem.automation !== 1}
                  />
                )}
              </div>
              <AlertBox {...alert} setAlert={setAlert} />
              {loading && <div className="alert alert-warning">loading</div>}
              {data.inventory_modem_pair?.modem.automation !== 1 && (
                <div className="alert alert-danger">
                  <b>Perhatian!</b> Modem tidak support
                </div>
              )}
              {response.result ? (
                <div
                  className="p-2 rounded-lg"
                  style={{
                    backgroundColor: "#D3D3D3",
                    width: "100%",
                    maxHeight: "300px",
                    overflowY: "auto",
                  }}
                >
                  <pre>{response.result}</pre>
                </div>
              ) : null}
            </div>
          </div>
        </LoadingInsideBoxWrapper>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-end">
          <button type="button" onClick={() => handleClose()} className="btn btn-secondary mr-1">
            Tutup
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

function ButtonCheckStatusModem({ id, setResponse, onLoading, onSuccess, onError, disabled }) {
  let [loading, setLoading] = useState(false);

  const checkModemStatus = async () => {
    setLoading(true);
    onLoading(true);
    try {
      const { data } = await checkCustomerModem(id);
      setResponse(data.data);
      onSuccess(data.message);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        onError(data.message);
      } else {
        onError("Something Error Happened");
      }
    }
    setLoading(false);
    onLoading(false);
  };
  return (
    <button
      className="btn btn-primary mr-2"
      disabled={loading || disabled}
      onClick={() => checkModemStatus()}
    >
      {loading ? (
        <>Proses cek data modem ...</>
      ) : (
        <>
          <i className="fa fa-signal"></i> Cek Status Modem
        </>
      )}
    </button>
  );
}

function ButtonRestartModem({ id, onLoading, onSuccess, onError, disabled }) {
  let [loading, setLoading] = useState(false);

  const restartModem = async () => {
    setLoading(true);
    onLoading(true);
    try {
      const { data } = await restartCustomerModem(id);
      onSuccess(data.message);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        onError(data.message);
      } else {
        onError("Something Error Happened");
      }
    }
    setLoading(false);
    onLoading(false);
  };
  return (
    <button
      className="btn btn-danger mr-2"
      disabled={loading || disabled}
      onClick={() => restartModem()}
    >
      {loading ? (
        <>Restarting modem ...</>
      ) : (
        <>
          <i className="fa fa-repeat"></i> Restart Modem
        </>
      )}
    </button>
  );
}
