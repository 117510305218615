import { convertToRupiah } from "../../tools/numerictools";
import Box from "../Box";

export function DataRegistrationBoxes({ data }) {
  // let schedule = data.registration_schedules.find(schedule => schedule.schedule.type === type);

  return (
    <>
      <Box title="Data Instalasi">
        <table className="table table-bordered">
          <tbody>
            <tr>
              <td>Nomor Registrasi</td>
              <td>{data.registration?.registration_number}</td>
            </tr>
            <tr>
              <td>Status Pembayaran</td>
              <td>{data.billing_status || "Billing belum tercetak"}</td>
            </tr>
            <tr>
              <td>Nama Lengkap</td>
              <td>{data.registration?.name}</td>
            </tr>
            <tr>
              <td>Nomor Telepon</td>
              <td>{data.registration?.phone_number}</td>
            </tr>
            <tr>
              <td>Nomor Registrasi</td>
              <td>{data.registration?.registration_number}</td>
            </tr>
            {data.registration.installation_address && (
              <tr>
                <td>Alamat Pemasangan</td>
                <td>
                  {data.registration.installation_address.address}, RT{" "}
                  {data.registration.installation_address.rt}/RW{" "}
                  {data.registration.installation_address.rw},{" "}
                  {data.registration.installation_address.kelurahan},{" "}
                  {data.registration.installation_address.kecamatan},{" "}
                  {data.registration.installation_address.kota_kab},{" "}
                  {data.registration.installation_address.province},{" "}
                  {data.registration.installation_address.postal_code}
                </td>
              </tr>
            )}

            <tr>
              <td>Teknisi</td>
              <td>
                <ol className="pl-3">
                  {data.technicians.map((technician, index) => (
                    <li key={index}>{technician.name}</li>
                  ))}
                </ol>
              </td>
            </tr>
          </tbody>
        </table>
      </Box>
      {data.bast_survey !== null && (
        <Box title="Hasil Survey">
          <p className="font-weight-bold">Item Tambahan</p>
          <p>{data.bast_survey.additional_item ? "Ya" : "Tidak"}</p>

          {data.bast_survey.additional_items.length !== 0 && (
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th colSpan={3}>Tambahan</th>
                </tr>
                <tr>
                  <th>Item</th>
                  <th>Jumlah</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {data.bast_survey.additional_items.map((item, index) => (
                  <tr key={index}>
                    <td>{item.name}</td>
                    <td>
                      {item.qty} {item.unit}
                    </td>
                    <td>Rp. {convertToRupiah(item.amount || 0)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </Box>
      )}
    </>
  );
}
