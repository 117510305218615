import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSetupModemListHooks } from "../../../utils/InventoryHooks";
import { useALlFramedPool } from "../../../utils/radius/FramedPoolUtil";
import AlertBox from "../../AlertBox";
import Box from "../../Box";
import Pagination from "../../Pagination";
import ModalDownloadModem from "./ModalDownloadModem";

export default function BoxSetupModem() {
  const [ formFilter, setFormFilter ] = useState({name: '', registration_number: '', serial_number_modem: '', odp: '', odc: '', framed_pool: ''});
  const [filter, setFilter] = useState({name: '', registration_number: '', serial_number_modem: '', odp: '', odc: '', framed_pool: '', page: 1, page_size: 10});
  const [alert, setAlert] = useState({show: false, message: '', type: ''});

  let framedPools = useALlFramedPool({});
  
  let modemlist = useSetupModemListHooks({ filter });
  let [selectedModem, setSelectedModem] = useState({});

  const [showModalDetail, setShowModalDetail] = useState(false);
  const [showModalDownload, setShowModalDownload] = useState(false);

  const toggleModalDetail = (selected = {}) => {
    setSelectedModem(selected)
    setShowModalDetail(!showModalDetail)
  }

  useEffect(() => {
    modemlist.fetchSetupModem(filter);
  }, [filter])

  const applyFilter = () => {
    setFilter({ ...filter, ...formFilter, page: 1 });
  }

  const resetFilter = () => {
    setFormFilter({name: '', registration_number: '', serial_number_modem: '', odp: '', odc: ''});
    setFilter({name: '', registration_number: '', serial_number_modem: '', odp: '', odc: '', page: 1, page_size: 10});
  }

  const gotoPage = (page) => {
    setFilter({ ...filter, page: page})
  }

  const onSuccess = (message) => {
  setAlert({show: true, message, type: 'success'})
  }

  return (
    <>
      <Box title="Modem Sudah Setup" loading={modemlist.loading}>
        <div className="d-flex justify-content-end mb-3">
          <button className="btn btn-primary btn-sm" onClick={() => setShowModalDownload(true)}><i className="fas fa-download"></i> Download Data Modem</button>
        </div>
        <div className="row mb-4">
          <div className="col-sm-2">
            <div className="form-group">
              <label>No. Reg</label>
              <input type="text" className="form-control" value={formFilter.registration_number} onChange={event => setFormFilter({ ...formFilter, registration_number: event.target.value})}  />
            </div>
          </div>
          <div className="col-sm-2">
            <label>Nama</label>
            <input type="text" className="form-control" value={formFilter.name} onChange={event => setFormFilter({ ...formFilter, name: event.target.value})}  />
          </div>
          <div className="col-sm-2">
            <div className="form-group">
              <label>S/N Modem</label>
              <input type="text" className="form-control" value={formFilter.serial_number_modem} onChange={event => setFormFilter({ ...formFilter, serial_number_modem: event.target.value})}  />
            </div>
          </div>
          <div className="col-sm-2">
            <div className="form-group">
              <label>ODP</label>
              <input type="text" className="form-control" value={formFilter.odp} onChange={event => setFormFilter({ ...formFilter, odp: event.target.value})}  />
            </div>
          </div>
          <div className="col-sm-2">
            <div className="form-group">
              <label>ODC</label>
              <input type="text" className="form-control" value={formFilter.odc} onChange={event => setFormFilter({ ...formFilter, odc: event.target.value})}  />
            </div>
          </div>
          <div className="col-sm-2">
            <div className="form-group">
              <label>Framed Pools</label>
              <select className="form-control" value={formFilter.framed_pool} onChange={event => setFormFilter({ ...formFilter, framed_pool: event.target.value})} >
                <option value=""></option>
                {framedPools.data.map((framed_pool, index) => (
                <option key={index} value={framed_pool.name}>{framed_pool.name}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-auto">
            <div className="form-group">
              <button className="btn btn-primary" style={{marginTop: '0px'}} onClick={() => applyFilter()}>Filter</button>
              <button className="btn" style={{marginTop: '0px'}} onClick={() => resetFilter()}>Reset</button>
            </div>
          </div>
        </div>
        <AlertBox {...alert} setAlert={setAlert}/>
        <div style={{overflowX: "auto"}}>
          <table className="table table-striped table-bordered table-hover">
            <thead>
              <tr>
                <th>No. Reg</th>
                <th>Nama</th>
                <th>Paket</th>
                <th>Serial</th>
                <th>ODP</th>
                <th>ODC</th>
                <th>OLT</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {modemlist.modems.length === 0 && 
                <tr>
                  <td colSpan={8} className="text-center">Tidak ada data</td>
                </tr>
              }
              {modemlist.modems.map((modem, index) => (
                <tr key={index}>
                  <td>{modem.inventory_modem_pair.registration.registration_number}</td>
                  <td>{modem.inventory_modem_pair.registration.name}</td>
                  <td>{modem.inventory_modem_pair.registration.internet_package?.name} ({modem.inventory_modem_pair.registration.internet_package?.internet_package_varian.amount} {modem.inventory_modem_pair.registration.internet_package?.internet_package_varian.unit})</td>
                  <td>{modem.serial_number_modem}</td>
                  <td>{modem.odp}</td>
                  <td>{modem.odc}</td>
                  <td>{modem.olt}</td>
                  <td>
                    <button className="btn btn-sm btn-primary" onClick={() => toggleModalDetail(modem)}><i className="fa fa-eye"></i></button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {modemlist.meta.count > modemlist.meta.page_size &&
          <Pagination 
            totalpage={Math.ceil(
              modemlist.meta.count /
              modemlist.meta.page_size
            )}
            selectedpage={modemlist.meta.page}
            clickpage={page => gotoPage(page)}
            next={() => gotoPage(modemlist.meta.page + 1)}
            prev={() => gotoPage(modemlist.meta.page - 1)}
          />
        }
      </Box>
      
      <ModalDownloadModem show={showModalDownload} onClose={() => setShowModalDownload(false)} onSuccess={onSuccess} />
      <Modal show={showModalDetail} onHide={() => toggleModalDetail()} size="md">
        <Modal.Header closeButton>
          <Modal.Title>Detail Modem</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table table-bordered">
            <tbody>
              <tr>
                <td>Nomor Registrasi</td>
                <td>{selectedModem.inventory_modem_pair?.registration?.registration_number || '-'}</td>
              </tr>
              <tr>
                <td>Nama</td>
                <td>{selectedModem.inventory_modem_pair?.registration?.name || '-'}</td>
              </tr>
              <tr>
                <td>Paket</td>
                <td>{selectedModem.inventory_modem_pair?.registration?.internet_package.name} ({selectedModem.inventory_modem_pair?.registration?.internet_package.internet_package_varian.amount} {selectedModem.inventory_modem_pair?.registration?.internet_package.internet_package_varian.unit})</td>
              </tr>
              <tr>
                <td>Serial Modem</td>
                <td>{selectedModem.serial_number_modem || '-'}</td>
              </tr>
              <tr>
                <td>Serial GPON</td>
                <td>{selectedModem.serial_number_gpon || '-'}</td>
              </tr>
              <tr>
                <td>PPPoE Username</td>
                <td>{selectedModem.pppoe_username || '-'}</td>
              </tr>
              <tr>
                <td>PPPoE Password</td>
                <td>{selectedModem.pppoe_password || '-'}</td>
              </tr>
              <tr>
                <td>Framed Pool</td>
                <td>{selectedModem.framed_pool || '-'}</td>
              </tr>
              <tr>
                <td>VLAN</td>
                <td>{selectedModem.vlan || '-'}</td>
              </tr>
              <tr>
                <td>ODP</td>
                <td>{selectedModem.odp || '-'}</td>
              </tr>
              <tr>
                <td>ODC</td>
                <td>{selectedModem.odc || '-'}</td>
              </tr>
              <tr>
                <td>OLT</td>
                <td>{selectedModem.olt || '-'}</td>
              </tr>
            </tbody>
          </table>
        </Modal.Body>
      </Modal>
    </>
  )
}