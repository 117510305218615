import { useState } from "react";
import { Modal } from "react-bootstrap";
import { createPartner } from "../../../api/projects";
import AlertBox from "../../AlertBox";
import { RequiredAsterisk } from "../../FormHelper";

export default function ModalPartnerCreate({ show, onClose, onSuccess }) {
  let [alert, setAlert] = useState({ show: false, message: "", type: "" });
  let [form, setForm] = useState({
    name: "",
  });

  const handleClose = () => {
    resetForm();
    onClose();
  };

  const resetForm = () => {
    setAlert({ show: false, message: "", type: "" });
    setForm({
      ...form,
      name: "",
    });
  };

  const sendData = async (formData) => {
    try {
      let response = await createPartner(formData);
      onSuccess(true, response.data.message);
      handleClose();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message, type: "danger" });
    }
  };

  const onSubmitData = (event) => {
    event.preventDefault();
    let formData = { ...form };
    sendData(formData);
  };

  return (
    <Modal show={show} onHide={() => handleClose()} size="md">
      <form onSubmit={(event) => onSubmitData(event)}>
        <Modal.Header closeButton>
          <Modal.Title>Tambah Data Mitra</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AlertBox {...alert} setAlert={setAlert} />
          <div className="row">
            <div className="col-lg-12">
              <div className="form-group">
                <label htmlFor="vendorName">
                  Nama Mitra <RequiredAsterisk />
                </label>
                <input
                  type="text"
                  required
                  value={form.name}
                  onChange={(event) => setForm({ ...form, name: event.target.value })}
                  className="form-control"
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={() => handleClose()} type="button">
            Close
          </button>
          <button className="btn btn-primary">Tambah Mitra</button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
