import { useEffect, useState } from "react";
import AlertBox from "../../components/AlertBox";
import Box from "../../components/Box";
import MainLayout from "../../components/layouts/MainLayout";
import ModalEditDataPermission from "../../components/setting/ModalEditDataPermission";
import { TogglePermissionRole } from "../../components/setting/TogglePermissionRole";
import { useControlPermission } from "../../utils/ActionPermissionUtils";
import {
  useAllRolesListHooks,
  usePermissionsByRoleHooks,
  usePermissionsListHooks,
  useResourcesListHooks,
} from "../../utils/PermissionManageUtils";

export default function RbacSettingPage() {
  let { hasAccess } = useControlPermission();

  let [roleId, setRoleId] = useState(null);

  const [selectedId, setSelectedId] = useState(null);
  const [showModalEdit, setShowModalEdit] = useState(false);

  let [formFilter, setFormFilter] = useState({ r: "", type: "", q: "" });
  let [filter, setFilter] = useState({
    role: "",
    r: "",
    type: "",
    q: "",
    page: 1,
    page_size: 100,
  });
  let [permissionForm, setPermissionForm] = useState({});

  let [alert, setAlert] = useState({ show: false, message: "", type: "" });

  let { permissions, loading, getPermissions } = usePermissionsListHooks({ filter });
  let {
    permissions: permissionsByRole,
    loading: permissionsByRoleLoading,
    fetched: permissionByRoleFetched,
    getPermissionsByRole,
  } = usePermissionsByRoleHooks({ id: roleId });
  const { roles } = useAllRolesListHooks();
  const { resources } = useResourcesListHooks();

  useEffect(() => {
    if (permissionByRoleFetched) {
      let form = {};
      permissionsByRole.forEach((permission) => {
        form[permission.id] = true;
      });

      setPermissionForm(form);
    }
  }, [permissionsByRole, permissionByRoleFetched]);

  const changeFilter = (name, value) => {
    setFormFilter({ ...formFilter, [name]: value });
  };

  const applyFilter = () => {
    setFilter({ ...filter, ...formFilter });
  };

  const toggleModalEdit = (id = null) => {
    setSelectedId(id);
    setShowModalEdit(!showModalEdit);

    if (id === null) {
      getPermissions(filter);
    }
  };

  const resetFilter = () => {
    setFormFilter({ r: "", type: "", q: "" });
    setFilter({ ...filter, r: "", type: "", q: "", page: 1, page_size: 100 });
  };

  const onSuccess = (message = "") => {
    setAlert({ show: true, message: message, type: "success" });
    getPermissionsByRole(roleId);
  };

  return (
    <MainLayout resource_key="setting-permission">
      <div className="container-fluid">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Pengaturan RBAC</h1>
        </div>
        <div className="row">
          <div className="col-12">
            <Box title="Daftar Action Control" loading={loading || permissionsByRoleLoading}>
              <div className="row">
                <div className="col-12 col-lg-3">
                  <div className="list-group d-none d-lg-block">
                    {roles.map((role) => (
                      <button
                        key={role.id}
                        className={`list-group-item list-group-item-action ${
                          role.id === roleId ? "active" : ""
                        }`}
                        type="button"
                        onClick={() => {
                          setRoleId(role.id);
                        }}
                      >
                        {role.name}
                      </button>
                    ))}
                  </div>
                  <div className="form-group d-lg-none">
                    <label>Pilih Role</label>
                    <select
                      value={roleId || ""}
                      onChange={(event) => setRoleId(event.target.value)}
                      className="form-control"
                    >
                      <option value="">-- Pilih Role --</option>
                      {roles.map((role) => (
                        <option key={role.id} value={role.id}>
                          {role.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-12 col-lg-9">
                  <div className="row mb-4">
                    <div className="col-12 col-md-2">
                      <label>Type</label>
                      <select
                        className="form-control"
                        value={formFilter.type}
                        onChange={(event) => changeFilter("type", event.target.value)}
                      >
                        <option value="">SEMUA</option>
                        <option value="menu">Menu</option>
                        <option value="api">API</option>
                      </select>
                    </div>
                    <div className="col-12 col-md-2">
                      <label>Resource</label>
                      <select
                        className="form-control"
                        value={formFilter.r}
                        onChange={(event) => changeFilter("r", event.target.value)}
                      >
                        <option value="">SEMUA</option>
                        {resources.map((resource, index) => (
                          <option value={resource.name} key={index}>
                            {resource.display_name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-12 col-md-2">
                      <label>Pencarian</label>
                      <input
                        type="text"
                        className="form-control"
                        value={formFilter.q}
                        onChange={(event) => changeFilter("q", event.target.value)}
                        placeholder="Action Name / Display Name / Desc"
                      />
                    </div>
                    <div className="col-12 col-md">
                      <button
                        className="btn btn-primary"
                        onClick={() => applyFilter()}
                        style={{ marginTop: "32px" }}
                      >
                        Filter
                      </button>
                      <button
                        className="btn btn-default"
                        onClick={() => resetFilter()}
                        style={{ marginTop: "32px" }}
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                  <AlertBox {...alert} setAlert={setAlert} />
                  {permissions.map((controlGroup, index) => (
                    <div className="mb-4" key={index}>
                      <h4>
                        {controlGroup.display_name} <BtnKeyView resource_key={controlGroup.name} />{" "}
                      </h4>

                      <div style={{ overflowX: "auto" }}>
                        <table className="table table-striped table-hover table-bordered">
                          <thead>
                            <tr>
                              <th></th>
                              <th>Action</th>
                              <th>Name</th>
                              <th>Type</th>
                              <th>Desc</th>
                              <th className="text-center">Izin</th>
                            </tr>
                          </thead>
                          <tbody>
                            {controlGroup.permissions.map((permission) => (
                              <tr key={permission.id}>
                                <td width={60}>
                                  {hasAccess("update-permission") && (
                                    <button
                                      className="btn btn-sm btn-info"
                                      onClick={() => toggleModalEdit(permission.id)}
                                    >
                                      <i className="fa-solid fa-pencil"></i>
                                    </button>
                                  )}
                                </td>
                                <td>{permission.name}</td>
                                <td>{permission.display_name || "-"}</td>
                                <td>{permission.types.toString()}</td>
                                <td>{permission.description || "-"}</td>
                                <td className="text-center">
                                  <TogglePermissionRole
                                    value={permissionForm[permission.id] || false}
                                    permission_id={permission.id}
                                    role_id={roleId}
                                    onSuccess={onSuccess}
                                    disabled={!hasAccess("change-permission-role")}
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Box>
          </div>
        </div>
      </div>

      <ModalEditDataPermission
        show={showModalEdit}
        setShow={toggleModalEdit}
        id={showModalEdit ? selectedId : null}
        onSuccess={onSuccess}
      />
    </MainLayout>
  );
}

const BtnKeyView = ({ resource_key }) => {
  let [show, setShow] = useState(false);
  return (
    <>
      <button className="btn btn-sm" onClick={() => setShow(!show)}>
        <i className="fa fa-key"></i>
      </button>{" "}
      <small>{show ? `${resource_key}` : ""}</small>
    </>
  );
};
