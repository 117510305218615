import { apiAuth, apiUploader } from "./base";

export function getAllSettings(params) {
  let api = apiAuth();
  return api.get(`v1/settings`, { params });
}

export function saveSettings(data) {
  let api = apiAuth();
  return api.put(`v1/settings`, data);
}

export function getDefaultInventory(params) {
  let api = apiAuth();
  return api.get(`v1/settings/default-inventories`, { params });
}

export function getKPISetting(params) {
  let api = apiAuth();
  return api.get(`v1/settings/kpi`, { params });
}

export function updateDefaultInventory(data) {
  let api = apiAuth();
  return api.put(`v1/settings/default-inventories`, data);
}

export function updateKpi(data) {
  let api = apiAuth();
  return api.put(`v1/settings/kpi`, data);
}

export function getListProvinceStatus() {
  let api = apiAuth();
  return api.get(`v1/presets/address/coverage?type=province`);
}

export function updateProvinceCoverage(id, data) {
  let api = apiAuth();
  return api.put(`v1/presets/address/${id}/coverage`, data);
}

export function getPresetSurveyDefaultItem() {
  let api = apiAuth();
  return api.get('v1/presets/ba-survey-items');
}

export function getAuditLog(params) {
  let api = apiAuth();
  return api.get(`v1/audits/logs`, { params });
}

export function getPaymentSetting() {
  let api = apiAuth();
  return api.get(`v1/settings/payment-method`);
}

export function savePaymentSetting(data) {
  let api = apiAuth();
  return api.put(`v1/settings/payment-method`, data);
}

export function getAllInfoAlert(params) {
  let api = apiAuth();
  return api.get(`v1/usermobile/infoall`, { params });
}

export function createAppAlert(data) {
  let api = apiAuth();
  return api.post(`v1/usermobile/createinfo`, data);
}

export function updateAppAlert(id, data) {
  let api = apiAuth();
  return api.post(`v1/usermobile/updateinfo`, data,{params: { infoUUID: id }});
}

export function deleteAppAlert(params) {
  let api = apiAuth();
  return api.get(`v1/usermobile/delinfo`, {params});
}

export function getAllAppBanner(params) {
  let api = apiAuth();
  return api.get(`v1/usermobile/bannerall`, { params });
}

export function createAppBanner(data) {
  let api = apiUploader();
  return api.post(`v1/usermobile/createbanner`, data);
}

export function updateAppBanner(id, data) {
  let api = apiUploader();
  return api.post(`v1/usermobile/updatebanner`, data, { params: { bannerUUID: id } });
}

export function deleteAppBanner(params) {
  let api = apiAuth();
  return api.get(`v1/usermobile/delbanner`, {params});
}