import { useState } from "react";
import Box from "../../components/Box";
import MainLayout from "../../components/layouts/MainLayout";
import ImageUploader from "../../components/ImageUploader";
import AlertBox from "../../components/AlertBox";
import {useBastScheduleListHooks, useScheduleDismantleHooks} from '../../utils/SchedulesUtil'
import { bastDismantleModem } from "../../api/schedules";
import DropdownSelectHelper from "../../components/technician/DropdownSelectHelper";

export default function ReportBastDismantlePages () {
  let [ filter, setFilter ] = useState({ type: 'dismantle' });

  let { schedules } = useBastScheduleListHooks({filter});
  let [ number, setNumber ] = useState("");
  let { data: dismantleData,getSchedule, fetched: dismantleDataFetched, loading: dismantleDataLoading, error: dismantleDataError, resetData: resetDismantleData } = useScheduleDismantleHooks({ number });
  let [ form, setForm ] = useState({
    inventory: '',
    signature: ''
  });
  let [helper, setHelper] = useState(null);

  let [alert, setAlert] = useState({show: false, message: '', type: ''});


  const onSuccess = (message) => {
    setAlert({show: true, message: message, type: 'success'});
    setFilter({ type: 'dismantle' });
    resetData();
  }
  
  const resetData = () => {
    // reset data
    resetDismantleData();
    // reset form
    setNumber("");
    setHelper(null);
    setForm({
      inventory: '',
      signature: ''
    });
  }

  const sendReport = async (form_data) => {
    try {
      let { data } = await bastDismantleModem(dismantleData.id, form_data);
      onSuccess(data.message);
    } catch (error) {
      let message = ""
      if (error.response) {
        let { data } =  error.response;
        message = data.message;
      } else {
        message = "Something Error Happened"
      }

      setAlert({show: true, message: message, type: 'danger'});
    }
    topFunction()
  }

  const topFunction = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  const onChangeRegNumber = (value) => {
    setNumber(value);
    getSchedule(value);
  }

  const onChangeImg = (type, image_url) => {
    setForm({ ...form, [type]: image_url})
  }

  const onSubmitData = (event) => {
    event.preventDefault();

    let formData = {
      helper_id: helper?.value || "",
      attachments: [
        {
          type: 'inventory',
          url: form.inventory
        },
        {
          type: 'signature',
          url: form.signature
        }
      ]
    }

    sendReport(formData)
  }

  return (
    <MainLayout>
      <div className="container-fluid">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">BAST Dismantle</h1>
        </div>

        {/* Selector */}
        <form>
          <div className="row">
              <div className="col-auto">
                <div className="form-group">
                  <label htmlFor="registration_number">Nomor Registrasi</label>
                  <select className="form-control" value={number} onChange={event => onChangeRegNumber(event.target.value)}>
                    <option value="" disabled>Pilih Nomor Registrasi</option>
                    {schedules.map((dismantle, index) => (
                      <option value={dismantle.id} key={index}>{dismantle.registration_number}</option>
                    ))}
                  </select>
                </div>
              </div>
          </div>
        </form>

        {/* Card Box */}
        {!dismantleDataFetched &&
          <div className="card mb-4 border-left-info">
            <div className="card-body">
              Masukan <b>nomor registrasi</b> terlebih dahulu
            </div>
          </div>
        }

        {dismantleDataLoading && 
          <AlertBox show={true} message="Memuat data dismantle" type="warning"  setAlert={() => {}} />
        }

        {dismantleDataError && 
          <AlertBox show={true} message={dismantleDataError} type="danger"  setAlert={() => {}} />
        }

        <div className="row">
          <div className="col">
            <AlertBox {...alert} setAlert={setAlert} />
          </div>
        </div>

        {/* Content */}
        {dismantleDataFetched &&
          <div className="row">
            <div className="col-sm-4">
              <Box
                title="Data Pelanggan"
              >
                <table className='table table-bordered'>
                  <tr>
                    <td colSpan={2}><b>Data Pelanggan</b></td>
                  </tr>
                  <tr>
                    <td>No. Reg</td>
                    <td>{dismantleData.registration?.registration_number || "-"}</td>
                  </tr>
                  <tr>
                    <td>Nama Pelanggan</td>
                    <td>{dismantleData.registration?.name || "-"}</td>
                  </tr>
                  <tr>
                    <td>Alamat Instalasi</td>
                    <td>{dismantleData.registration?.installation_address.address + " RT " + dismantleData.registration?.installation_address.rt + "/RW " + dismantleData.registration?.installation_address.rw   + ", " + dismantleData.registration?.installation_address.kelurahan + ", " + dismantleData.registration?.installation_address.kecamatan + ", " + dismantleData.registration?.installation_address.kota_kab + ", " + dismantleData.registration?.installation_address.province + ", " + dismantleData.registration?.installation_address.postal_code}</td>
                  </tr>
                  <tr>
                    <td>No. Telp</td>
                    <td>{dismantleData.registration?.phone_number || "-"}</td>
                  </tr>
                  <tr>
                    <td>Alasan Dismantle</td>
                    <td>{dismantleData.description || "-"}</td>
                  </tr>
                  <tr>
                    <td>Status Dismantle</td>
                    <td>{dismantleData.status || "-"}</td>
                  </tr>
                  <tr>
                    <td>Nama Teknisi</td>
                    <td>
                      <ol className="pl-3">
                        {dismantleData.technicians?.map((technician, indexY) => (
                          <li key={indexY}>{technician.name}</li>
                        ))}
                      </ol>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}><b>Data Modem</b></td>
                  </tr>
                  <tr>
                    <td>SN Modem</td>
                    <td>{dismantleData.registration?.inventory_modem_pair?.modem.serial_number_modem || "-"}</td>
                  </tr>
                  <tr>
                    <td>GPON</td>
                    <td>{dismantleData.registration?.inventory_modem_pair?.modem.serial_number_gpon || "-"}</td>
                  </tr>
                  <tr>
                    <td>Framed Pool</td>
                    <td>{dismantleData.registration?.inventory_modem_pair?.modem.framed_pool || "-"}</td>
                  </tr>
                  <tr>
                    <td>VLAN</td>
                    <td>{dismantleData.registration?.inventory_modem_pair?.modem.vlan || "-"}</td>
                  </tr>
                  <tr>
                    <td>ODP</td>
                    <td>{dismantleData.registration?.inventory_modem_pair?.modem.odp || "-"}</td>
                  </tr>
                  <tr>
                    <td>ODC</td>
                    <td>{dismantleData.registration?.inventory_modem_pair?.modem.odc || "-"}</td>
                  </tr>
                  <tr>
                    <td>OLT</td>
                    <td>{dismantleData.registration?.inventory_modem_pair?.modem.olt || "-"}</td>
                  </tr>
                </table>
              </Box>
            </div>
            <div className="col-sm-6">
              <Box
                title="Form BAST Dismantle"
              >
                <form onSubmit={event => onSubmitData(event)}>
                  <div className="form-group">
                    <label>Helper</label>
                    <DropdownSelectHelper value={helper} onChangeValue={selected => setHelper(selected)} />
                  </div>
                  <div className="py-4">
                    <h4>Dokumen Pendukung</h4>
                  </div>
                  <div className="form-group mb-4">
                    <label>Perangkat</label>
                    <ImageUploader
                      image={form.inventory}
                      type="inventory"
                      onChangeImg={onChangeImg}
                      required={true}
                    />
                  </div>
                  <div className="form-group mb-4">
                    <label>Tanda Tangan Pelanggan</label>
                    <ImageUploader
                      image={form.signature}
                      type="signature"
                      onChangeImg={onChangeImg}
                      required={true}
                    />
                  </div>

                  <button className="btn btn-primary" type="submit">Simpan BAST Dismantle</button>
                </form>

              </Box>
            </div>
          </div>
        }
      </div>
    </MainLayout>
  )
}